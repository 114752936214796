<template>
  <svg :class="className" :style="style" xmlns="http://www.w3.org/2000/svg">
    <title v-if="title">{{ title }}</title>
    <use :xlink:href="iconPath" xmlns:xlink="http://www.w3.org/1999/xlink"/>
  </svg>
</template>

<script>
export default {
  name: 'svg-icon',

  props: {
    name: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      default: null,
    },

    width: {
      default: null,
    },

    height: {
      default: null,
    },
  },

  computed: {
    iconPath() {
      let icon = require(`@/assets/svgs/symbols/${this.name}.svg`);
      if (Object.prototype.hasOwnProperty.call(icon, 'default')) {
        icon = icon.default;
      }

      return icon.url;
    },

    className() {
      return 'svg-icon svg-icon--' + this.name;
    },

    style() {
      const style = {};
      if (this.width) {
        style.width = this.width + 'px';
      }
      if (this.height) {
        style.height = this.height + 'px';
      }
      return style;
    },
  },
};
</script>
